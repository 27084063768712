import styled from 'styled-components';
import { themeGet } from 'styled-system';

const RangeBoxWrapper = styled.div`
  * {
    user-select: none;
  }

  .rc-slider-step {
    height: 5px;
  }
  .rc-slider-rail {
    height: 6px;
    border-radius: 12px;
  }

  .rc-slider-track {
    height: 6px;
    background-color: ${themeGet('colors.primary')};
  }

  .rc-slider-dot-active {
    border-color: ${themeGet('colors.primary')};
  }

  .rc-slider-dot {
    bottom: -4px;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    border-width: 3px;
  }

  .rc-slider-dot-active {
    border-color: ${themeGet('colors.primary')};
  }

  .rc-slider-handle {
    border-width: 3px;
    width: 22px;
    height: 22px;
    margin-top: -9px;
    border-color: ${themeGet('colors.primary')};
  }

  .rc-slider-handle:focus {
    border-color: ${themeGet('colors.primary')};
    box-shadow: none;
    outline: none;
  }

  .rc-slider-handle:hover {
    border-color: ${themeGet('colors.primary')};
  }

  .rc-slider-mark-text {
    color: #0f2137;
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: 767px) {
    .rc-slider-mark-text {
      display: none;
    }
  }

  .rc-slider-mark {
    top: 22px;
  }
`;

const PricingTable = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #f2f4f7;
  border-radius: 5px;
  padding: 50px 35px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
  @media screen and (min-width: 767px) {
    flex-basis: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    justify-items: center;
    flex-wrap: wrap;
  }
  @media (max-width: 767px) {
    padding: 45px 35px;
  }
  box-shadow: #ccc 0 0 10px 0px;
`;

const PricingHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  @media (min-width: 767px) {
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
    flex-basis: 18%;
  }
`;

const PricingPrice = styled.div`
  display: block;
  text-align: center;
  margin-bottom: 30px;
  @media screen and (min-width: 767px) {
    margin-bottom: 0;
  }
`;

const PricingButton = styled.div`
  text-align: center;
  @media screen and (max-width: 766px) {
    margin-bottom: 55px;
  }
`;

const PricingList = styled.div`
  margin-bottom: 3rem;
  @media screen and (min-width: 767px) {
    display: flex;
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #ddd;
    flex-basis: 100%;
    justify-content: space-between;
  }
`;

const ListItem = styled.div`
  display: flex;
  @media screen and (max-width: 766px) {
    margin-bottom: 19px;
  }

  flex-basis: 25%;

  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon {
    color: #18d379;
    margin-right: 10px;
  }
`;

const SwitchWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  .reusecore__switch {
    .reusecore__field-label {
      font-size: 16px;
      font-weight: 400;
      color: #5c636c;
      cursor: pointer;
    }
    input[type='checkbox'] {
      &:checked {
        + div {
          width: 40px !important;
          background-color: ${themeGet('colors.primary')};
          > div {
            left: 17px !important;
          }
        }
      }
      + div {
        background-color: #f0f0f0;
        background-color: #f0f0f0;
        border: 0;
        width: 40px;
        height: 25px;
        > div {
          background-color: #fff;
          box-shadow: 0px 2px 3px 0.24px rgba(31, 64, 104, 0.25);
          width: 21px;
          height: 21px;
          top: 2px;
          left: 2px;
        }
      }
    }
  }
`;

const ReadMoreItem = styled.div`
  color: ${themeGet('colors.textColor')};
  margin-bottom: 5px;
  p {
    display: inline;
  }

  margin-bottom: 15px;
  margin-right: 5px;
`;

const ReadMoreColumn = styled.div`
  @media screen and (min-width: 767px) {
    flex-basis: 33.33%;
  }
`;

const ReadMoreWrapper = styled.div`
  @media screen and (min-width: 767px) {
    display: flex;
  }
`;

const Disclaimer = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: #808080;
  font-size: 0.85rem;
`;

const Ribbon = styled.div`
  font-size: 0.75rem;
  position: absolute;
  right: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: row-reverse;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  height: 32px;
  background-color: #303030;
  position: absolute;
  //top: 10px;
  bottom: 5px;
  @media screen and (min-width: 767px) {
    bottom: 10px;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: -16px;
    border-bottom: 16px solid #303030;
    border-top: 16px solid #303030;
    border-left: 16px solid transparent;
  }
`;

export {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  SwitchWrapper,
  RangeBoxWrapper,
  ReadMoreItem,
  ReadMoreColumn,
  ReadMoreWrapper,
  Disclaimer,
  Ribbon
};

export default PricingTable;
